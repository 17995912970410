import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  input,
  OnDestroy,
  OnInit,
  Output,
  signal
} from '@angular/core';
import { FormGroup, Validators, FormsModule, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { Store, provideState } from '@ngrx/store';
import {
  distinctUntilChanged,
  filter,
  map,
  Observable,
  startWith,
  Subscription,
  take,
  throttleTime
} from 'rxjs';
import { LogService } from '../../core/log/log.service';
import { Category } from '../../domain/category/category.model';
import { City } from '../../domain/city/city.model';
import { Province } from '../../domain/province/province.model';
import { Specialty } from '../../domain/specialty/specialty.model';
import { GUEST_USER, ListaGruppiEnum, UserDTO } from '../../domain/user/user.model';
import { selectUser } from '../../domain/user/user.selectors';
import { ChangePasswordDialogComponent } from '../dialog/change-password-dialog/change-password-dialog.component';
import { ConsentInfoDialogComponent } from '../dialog/consent-info-dialog/consent-info-dialog.component';
import { openDialog } from '../dialog/dn-dialog.actions';
import { evaluateOneOrZeroFromBoolean } from '../util/util';
import {
  AUTOCOMPLETE_MIN_LETTERS,
  DOCTOR_ID,
  IDS_TO_SHOW_CATEGORY_FIELDS,
  UserForm,
  UserFormConsentsForm,
  UserFormEcmInfos,
  UserFormPersonalInfos,
  UserFormType
} from './user-form.model';
import { UserFormService } from './user-form.service';
import { autocompleteObjectValidator } from '../util/validators';
import { EnvironmentService } from '../../../environments/environment.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AsyncPipe, NgClass } from '@angular/common';
import { UserService } from '../../domain/user/user.service';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ProfessionType } from '../../domain/profession/profession.model';
import { AgenasDisciplineType } from 'src/app/domain/agenas-discipline/agenas-discipline.model';

export function userFormServiceFactory(
  ts: TranslateService,
  fb: FormBuilder,
  userService: UserService,
  store: Store
) {
  return new UserFormService(ts, fb, userService, store);
}

@Component({
  selector: 'dottnet-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FormsModule, ReactiveFormsModule, NgClass, MatFormFieldModule, MatInputModule, MatButtonModule, MatRadioModule, MatDatepickerModule, MatAutocompleteModule, MatOptionModule, MatIconModule, AsyncPipe, TranslateModule, MatCheckboxModule],
  providers: [
    /* {
        provide: UserFormService,
        useFactory: userFormServiceFactory,
        deps: [ TranslateService, FormBuilder, UserService, Store]
    }, */
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})

export class UserFormComponent implements OnInit, OnDestroy {
  // userObservable may be undefined to show form in signing up mode (blank and with some extra fields)
  readonly user = input<UserDTO>(undefined);
  readonly categoryOptions$ = input<Observable<Category[]>>(new Observable());
  readonly specialtyOptions$ = input<Observable<Specialty[]>>(new Observable());
  readonly professionTypesOptions$ = input<Observable<ProfessionType[]>>(new Observable());
  readonly agenasDisciplineTypesOptions$ = input<Observable<AgenasDisciplineType[]>>(new Observable());
  readonly provinceOptions$ = input<Observable<Province[]>>(new Observable());
  readonly listaGruppi = input<ListaGruppiEnum[]>([]);
  readonly formType = input<UserFormType>(UserFormType.SIGNUP);
  readonly isSubmitting = input<boolean>(false);
  readonly showPersonal = input<boolean>(false);
  readonly showProfessional = input<boolean>(false);
  readonly showConsents = input<boolean>(false);
  readonly showEmail = input<boolean>(false);
  readonly showPwd = input<boolean>(false);
  readonly showEcm = input<boolean>(false);
  readonly ecmChecked = input<boolean>(false);

  // Internal state signal
  isecmCheckedInternal = signal(this.ecmChecked());

  // Observables
  readonly cityOptions$ = input<Observable<City[]>>(new Observable<City[]>());
  readonly requestForm$ = input<Observable<boolean>>(new Observable<boolean>());

  @Output() readonly lookupCities: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  readonly submitForm: EventEmitter<FormGroup<UserForm>> = new EventEmitter<FormGroup<UserForm>>();

  @Output() sendForm: EventEmitter<FormGroup<UserForm>> = new EventEmitter<FormGroup<UserForm>>();

  // Main form group
  componentForm: FormGroup<UserForm>;

  // Filtered options for autocompletes
  categoryFilteredOptions$: Observable<Category[]> = new Observable<Category[]>();
  specialtyFilteredOptions$: Observable<Specialty[]> = new Observable<Specialty[]>();
  provinceFilteredOptions$: Observable<Province[]> = new Observable<Province[]>();
  professionTypesFilteredOptions$: Observable<ProfessionType[]> = new Observable<ProfessionType[]>();
  agenasDisciplineTypesFilteredOptions$: Observable<AgenasDisciplineType[]> = new Observable<AgenasDisciplineType[]>();

  // Subcriptions
  userSub: Subscription = new Subscription();
  categorySub: Subscription = new Subscription();
  specialtySub: Subscription = new Subscription();
  provinceSub: Subscription = new Subscription();
  requestFormSub: Subscription = new Subscription();
  professionTypesSub: Subscription = new Subscription();
  agenasDisciplineTypesSub: Subscription = new Subscription();

  birthplaceFieldSub: Subscription = new Subscription();
  computeCFSub: Subscription = new Subscription();

  // Instance variables
  categoryOptions: Category[] = [];
  specialtyOptions: Specialty[] = [];
  provinceOptions: Province[] = [];
  professionTypesOptions: ProfessionType[] = [];
  agenasDisciplineTypesOptions: AgenasDisciplineType[] = [];

  privacyPolicyUrl: string;
  imprintUrl: string;


  constructor(
    private formService: UserFormService,
    private logService: LogService,
    private store: Store,
    private UserService: UserService,
    private environmentService: EnvironmentService,
    private changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.privacyPolicyUrl = this.environmentService.privacyPolicyUrl;
    this.imprintUrl = this.environmentService.imprintUrl;
    this.componentForm = this.buildForm(this.listaGruppi());


    // this.initVisualization();
    this.componentForm.updateValueAndValidity();

    this.userSub = this.store
      .select(selectUser)
      .pipe(filter((user) => user && user.idAnagrafica !== GUEST_USER.idAnagrafica))
      .subscribe((user) => this.precompileForm(user));

    this.categorySub = this.categoryOptions$().subscribe(
      (categories) => {
        this.categoryOptions = categories.filter((category) => category.idCategoria !== 73)
        // Update filtered options based on user input
        this.categoryFilteredOptions$ = this.category.valueChanges.pipe(
          startWith<string | Category>(''),
          map((value) => this.filterCategory(value, this.categoryOptions))
        );
      }
    );
    this.specialtySub = this.specialtyOptions$().subscribe(
      (specialties) => {
        this.specialtyOptions = specialties
        // Update filtered options based on user input
        this.specialtyFilteredOptions$ = this.specialty.valueChanges.pipe(
          startWith<string | Specialty>(''),
          map((value) => this.filterSpecialty(value, this.specialtyOptions))
        );

      }
    );

    this.professionTypesSub = this.professionTypesOptions$().subscribe(
      (professiontypes) => {
        this.professionTypesOptions = professiontypes
        // Update filtered options based on user input
        this.professionTypesFilteredOptions$ = this.professionType.valueChanges.pipe(
          startWith<string | ProfessionType>(''),
          map((value) => this.filterProfessionTypes(value, this.professionTypesOptions))
        );
      }
    );

    this.agenasDisciplineTypesSub = this.agenasDisciplineTypesOptions$().subscribe(
      (agenasdisciplinetypes) => {
        this.agenasDisciplineTypesOptions = agenasdisciplinetypes
        // Update filtered options based on user input
        this.agenasDisciplineTypesFilteredOptions$ = this.agenasDisciplineType.valueChanges.pipe(
          startWith<string | AgenasDisciplineType>(''),
          map((value) => this.filterAgenasDisciplineTypes(value, this.agenasDisciplineTypesOptions))
        );
      });

    this.provinceSub = this.provinceOptions$().subscribe((provinces) => {
      this.provinceOptions = provinces;
      // Update filtered options based on user input
      this.provinceFilteredOptions$ = this.registerProvince.valueChanges.pipe(
        startWith<string | Province>(''),
        map((value) => this.filterProvince(value, this.provinceOptions))
      );
    });

    this.requestFormSub = this.requestForm$().subscribe((send: boolean) => {
      this.componentForm.markAllAsTouched();
      if (send) {
        this.sendForm.emit(this.componentForm);
      }
    });

    // Listen for event to update CF
    this.computeCFSub = this.personalInfos.valueChanges
      .pipe(
        throttleTime(250),
        distinctUntilChanged((before, after) => JSON.stringify(before) === JSON.stringify(after))
      )
      .subscribe(() => this.calcolaCod());

  }

  onPlaceChange(fieldValue: string) {
    if (fieldValue.length > AUTOCOMPLETE_MIN_LETTERS) {
      this.logService.info('Emitting lookupCities with value', fieldValue);
      this.lookupCities.emit(fieldValue);
    }
  }

  onSpecChange(event: any) {

    const selectedSpecialty = event.option.value as Specialty;

    if (selectedSpecialty && selectedSpecialty.idDisciplinaAgenas) {
      this.agenasDisciplineTypesOptions$().pipe(
        filter(disciplines => !!disciplines && disciplines.length > 0),
        take(1)
      ).subscribe(disciplines => {
        const matchingDiscipline = disciplines.find(
          d => d.idDisciplinaAgenas === selectedSpecialty.idDisciplinaAgenas
        );

        if (matchingDiscipline) {
          this.agenasDisciplineType.setValue(matchingDiscipline);
        }
      });
    }

  }



  // Filter function to lookup for matches in category typeahead
  filterCategory(value: any, categoryOptions: Category[]): Category[] {
    const filterValue = value.descrizioneCategoria || value.toLowerCase().trim();
    return categoryOptions.filter((option) =>
      option.descrizioneCategoria.toLowerCase().includes(filterValue)
    );
  }

  // Filter function to lookup for matches in specialty typeahead
  filterSpecialty(value: any, specialtyOptions: Specialty[]): Specialty[] {
    const filterValue = value.descrizioneSpecializzazione || value.toLowerCase().trim();
    return specialtyOptions.filter((option) =>
      option.descrizioneSpecializzazione.toLowerCase().includes(filterValue)
    );
  }

  // Filter function to lookup for matches in province typeahead
  filterProvince(value: any, provinceOptions: Province[]): Province[] {
    const filterValue = value.descrizioneProvincia || value.toLowerCase().trim();
    return provinceOptions.filter((option) =>
      option.descrizioneProvincia.toLowerCase().includes(filterValue)
    );
  }


  // Filter function to lookup for matches in ProfessionTypes typeahead
  filterProfessionTypes(value: any, professionTypesOptions: ProfessionType[]): ProfessionType[] {
    const filterValue = value.descrizioneTipoProfessione || value.toLowerCase().trim();
    return professionTypesOptions.filter((option) =>
      option.descrizioneTipoProfessione.toLowerCase().includes(filterValue)
    );
  }

  // Filter function to lookup for matches in AgenasDisciplineTypes typeahead
  filterAgenasDisciplineTypes(value: any, agenasDisciplineTypesOptions: AgenasDisciplineType[]): AgenasDisciplineType[] {
    const filterValue = value.descrizioneDisciplina || value.toLowerCase().trim();
    return agenasDisciplineTypesOptions.filter((option) =>
      option.descrizioneDisciplinaAgenas.toLowerCase().includes(filterValue)
    );
  }


  ngOnDestroy(): void {
    if (this.userSub) this.userSub.unsubscribe();
    if (this.categorySub) this.categorySub.unsubscribe();
    if (this.specialtySub) this.specialtySub.unsubscribe();
    if (this.birthplaceFieldSub) this.birthplaceFieldSub.unsubscribe();
    if (this.computeCFSub) this.computeCFSub.unsubscribe();
    if (this.requestFormSub) this.requestFormSub.unsubscribe();
    if (this.provinceSub) this.provinceSub.unsubscribe();
  }

  get category() {
    return this.componentForm?.get('professionalInfos')?.get('category');
  }

  get specialty() {
    return this.componentForm.get('professionalInfos').get('specialty');
  }

  get registerNumber() {
    return this.componentForm.get('professionalInfos').get('registerForm').get('registerNumber');
  }

  get registerProvince() {
    return this.componentForm.get('professionalInfos').get('registerForm').get('registerProvince');
  }

  get bornAbroad() {
    return this.componentForm.get('personalInfos').get('bornAbroad');
  }

  get name() {
    return this.componentForm.get('personalInfos').get('name');
  }

  get surname() {
    return this.componentForm.get('personalInfos').get('surname');
  }

  get email() {
    return this.componentForm.get('personalInfos').get('email');
  }

  get gender() {
    return this.componentForm.get('personalInfos').get('gender');
  }

  get birthdate() {
    return this.componentForm.get('personalInfos').get('birthdate');
  }

  get codiceFiscale() {
    return this.componentForm.get('personalInfos').get('codiceFiscale');
  }

  get birthplace() {
    return this.componentForm.get('personalInfos').get('birthplace');
  }

  get marketing() {
    return this.consentsForm.get('marketing');
  }

  get marketingThirdParties() {
    return this.consentsForm.get('marketingThirdParties');
  }

  get profiling() {
    return this.consentsForm.get('profiling');
  }

  get profilingThirdParties() {
    return this.consentsForm.get('profilingThirdParties');
  }

  get cellulare() {
    return this.componentForm.get('ecmInfos').get('cellulare');
  }

  get professionPlace() {
    return this.componentForm.get('ecmInfos').get('professionPlace');
  }

  get professionType() {
    return this.componentForm.get('ecmInfos').get('professionType');
  }

  get agenasDisciplineType() {
    return this.componentForm.get('ecmInfos').get('agenasDiscipline');
  }


  get cap() {
    return this.componentForm.get('ecmInfos').get('cap');
  }

  get personalInfos(): FormGroup<UserFormPersonalInfos> {
    return this.componentForm.get('personalInfos') as FormGroup<UserFormPersonalInfos>;
  }

  get ecmInfos(): FormGroup<UserFormEcmInfos> {
    return this.componentForm.get('ecmInfos') as FormGroup<UserFormEcmInfos>;
  }


  get consentsForm(): FormGroup<UserFormConsentsForm> {
    return this.componentForm.get('consentsForm') as FormGroup<UserFormConsentsForm>;
  }

  errorMessage(field: string): string {
    return this.formService.buildErrorMessage<UserForm>(this.componentForm, field);
  }

  private buildForm(listaGruppi: ListaGruppiEnum[]): FormGroup<UserForm> {
    return this.formType() === UserFormType.EDIT
      ? this.formService.buildEditUserForm(listaGruppi)
      : this.formService.buildRegisterUserForm();
  }

  private precompileForm(user: UserDTO) {

    this.cellulare.setValue(user.cellulare);
    this.cap.setValue(user.cap);
    this.professionPlace.setValue(<City>{
      idComune: user.idComuneEsercizio,
      descrizioneComune: user.comuneEsercizio,
      belfiore: user.codiceBelfioreEsercizio
    });
    this.professionType.setValue({
      idTipoProfessione: user.idTipoProfessione,
      descrizioneTipoProfessione: user.tipoProfessione
    });
    this.agenasDisciplineType.setValue({
      idDisciplinaAgenas: user.idDisciplinaAgenas,
      descrizioneDisciplinaAgenas: user.disciplinaAgenas
    });

    if (user.cellulare?.length > 0) {
      this.isecmCheckedInternal.set(true);
    }

    this.name.setValue(user.nome);
    this.surname.setValue(user.cognome);

    // La mail non è editabile
    this.email.setValue(user.email);
    this.email.disable();

    this.gender.setValue(user.sesso);
    this.birthdate.setValue(user.dataNascita?.toString());

    this.birthplace.setValue(<City>{
      idComune: user.idComuneNascita,
      descrizioneComune: user.comuneNascita,
      belfiore: user.codiceBelfiore
    });

    if (user.comuneNascita) {
      if (user.idComuneNascita === 0) {
        // ESTERO
        this.bornAbroad.setValue('1');
      }
      else {
        this.bornAbroad.setValue('0');
      }
      // this.codiceFiscale.disable();
    } else {
      this.bornAbroad.setValue('0');
      // se sto mostrando i dati personali gestisco il flag di birthplace per comandare la validazione
      if (this.showPersonal()) {
        this.manageBirthplaceField()
        this.codiceFiscale.addAsyncValidators(this.UserService.checkCFExistence(this.store))
      }
    }

    // se posso calcolare il codice fiscale lo calcolo altrimenti prendo quello eventualmente memorizzato a db
    if (this.isCFComputable()) {
      this.codiceFiscale.setValue(
        this.formService.buildCodiceFiscale(
          this.name.value,
          this.surname.value,
          this.birthplace.value,
          this.birthdate.value,
          this.gender.value
        )
      );
    } else {
      this.codiceFiscale.setValue(user.codiceFiscale);
    }

    if (user.idCategoria) {
      this.category.setValue({
        idCategoria: user.idCategoria,
        descrizioneCategoria: user.categoria
      });

      if (user.idSpecializzazione) {
        this.specialty.setValue({
          idSpecializzazione: user.idSpecializzazione,
          descrizioneSpecializzazione: user.specializzazione,
          idDisciplinaAgenas: user.idDisciplinaAgenas
        });
      }

      if (this.isDoctorSelected()) {
        this.specialty.addValidators(Validators.required);
        this.specialty.addValidators(autocompleteObjectValidator());
      } else this.specialty.clearValidators();

      if (this.areShownCategoryFields() && this.showProfessional()) {
        this.registerNumber.addValidators(Validators.required);
        this.registerProvince.addValidators(Validators.required);
        this.registerProvince.addValidators(autocompleteObjectValidator());
      }
    }

    this.registerNumber.setValue(user.numeroIscrizioneAlbo?.toString());

    this.provinceOptions$()
      .pipe(filter((provinces) => provinces.length !== 0))
      .subscribe((provinces) => {
        provinces
          .filter((province) => province.siglaProvincia === user.provinciaIscrizioneAlbo)
          .forEach((elem) => {
            this.registerProvince.setValue(elem);
          });
      });

    this.marketing.setValue(evaluateOneOrZeroFromBoolean(user.marketing));
    this.marketingThirdParties.setValue(evaluateOneOrZeroFromBoolean(user.marketingTerzi));
    this.profiling.setValue(evaluateOneOrZeroFromBoolean(user.profilazione));
    this.profilingThirdParties.setValue(evaluateOneOrZeroFromBoolean(user.profilazioneTerzi));

    this.componentForm.markAllAsTouched();

    if (this.formType() === UserFormType.EDIT && this.componentForm.valid) {
      // in edit if status is valid I have to mark form as dirty in order to activate save button
      this.componentForm.markAsDirty();
    }
  }

  displayCategory(elem: Category) {
    return elem.descrizioneCategoria;
  }

  displaySpecialty(elem: Specialty): string {
    return elem.descrizioneSpecializzazione;
  }

  displayProvince(elem: Province): string {
    return elem.descrizioneProvincia;
  }

  displayCity(elem: City): string {
    return elem.descrizioneComune;
  }

  displayProfessionType(elem: ProfessionType): string {
    return elem.descrizioneTipoProfessione;
  }

  displayAgenasDisciplineType(elem: AgenasDisciplineType): string {
    return elem.descrizioneDisciplinaAgenas;
  }



  manageBirthplaceField() {
    if (this.bornAbroad.value === '1') {
      this.birthplace.setValue(<City>{
        idComune: 0,
        descrizioneComune: 'ESTERO'
      });
      this.birthplace.disable();
      this.birthplace.clearValidators();
      this.birthplace.updateValueAndValidity();
      // this.codiceFiscale.enable();
      this.codiceFiscale.setValue('');
    } else {
      this.birthplace.setValue(<City>{});
      this.birthplace.enable();
      this.birthplace.addValidators([autocompleteObjectValidator(), Validators.required]);
      this.birthplace.updateValueAndValidity();
      // this.codiceFiscale.disable();
      // this.codiceFiscale.setValue('');
    }
  }
  setEcmChecked() {
    this.isecmCheckedInternal.set(!this.isecmCheckedInternal());

    if (this.isecmCheckedInternal()) {
      this.cellulare.setValidators([Validators.required, Validators.pattern('[- +()0-9]{9,12}')]);
      this.cellulare.updateValueAndValidity();
      this.professionPlace.setValidators([autocompleteObjectValidator(), Validators.required]);
      this.professionPlace.addValidators([autocompleteObjectValidator(), Validators.required]);
      this.professionPlace.updateValueAndValidity();
      this.professionType.setValidators([autocompleteObjectValidator(), Validators.required]);
      this.professionType.addValidators([autocompleteObjectValidator(), Validators.required]);
      this.professionType.updateValueAndValidity();
      this.agenasDisciplineType.setValidators([autocompleteObjectValidator(), Validators.required]);
      this.agenasDisciplineType.addValidators([autocompleteObjectValidator(), Validators.required]);
      this.agenasDisciplineType.updateValueAndValidity();
      this.cap.setValidators([Validators.required, Validators.pattern('^[0-9]*$')]);
      this.cap.updateValueAndValidity();
    } else {
      this.cellulare.clearValidators();
      this.cellulare.updateValueAndValidity();
      this.professionPlace.clearValidators();
      this.professionPlace.updateValueAndValidity();
      this.professionType.clearValidators();
      this.professionType.updateValueAndValidity();
      this.agenasDisciplineType.clearValidators();
      this.agenasDisciplineType.updateValueAndValidity();
      this.cap.clearValidators();
      this.cap.updateValueAndValidity();
    }

  }


  manageCategoryFields() {
    if (this.areShownCategoryFields()) {
      this.registerNumber.addValidators(Validators.required);
      this.registerProvince.addValidators(Validators.required);
      this.registerProvince.addValidators(autocompleteObjectValidator());

      if (this.isDoctorSelected()) {
        this.specialty.addValidators(Validators.required);
        this.specialty.addValidators(autocompleteObjectValidator());
      } else {
        this.specialty.clearValidators();
      }
    } else {
      this.registerNumber.setValue('');
      this.registerProvince.setValue(<Province>{});
      this.registerNumber.clearValidators();
      this.registerProvince.clearValidators();
      this.specialty.clearValidators();
    }
    this.registerNumber.updateValueAndValidity();
    this.registerProvince.updateValueAndValidity();
    this.specialty.updateValueAndValidity();
  }

  areShownCategoryFields() {
    return IDS_TO_SHOW_CATEGORY_FIELDS.includes(this.category.value.idCategoria);
  }

  isDoctorSelected() {
    return DOCTOR_ID === this.category.value.idCategoria;
  }

  emitSubmit() {
    this.submitForm.emit(this.componentForm);
    this.componentForm.markAsPristine();
  }

  calcolaCod() {
    if (this.isCFComputable()) {
      this.codiceFiscale.setValue(
        this.formService.buildCodiceFiscale(
          this.name.value,
          this.surname.value,
          this.birthplace.value,
          this.birthdate.value,
          this.gender.value
        )
      );
    } else if (this.bornAbroad.value === '0') {
      this.codiceFiscale.setValue('');
    }
  }

  isCFComputable() {
    return (
      this.bornAbroad.value === '0' &&
      this.name.value &&
      this.surname.value &&
      this.birthplace?.value?.descrizioneComune &&
      this.birthdate.value &&
      this.gender.value
    );
  }


  changePassword() {
    this.store.dispatch(
      openDialog({
        content: undefined,
        urlToDownload: undefined,
        urlWithContext: undefined,
        componentType: ChangePasswordDialogComponent,
        panelClass: 'dn-dialog-small',
        data: { idAnagrafica: this.user().idAnagrafica }
      })
    );
  }

  // Dialog

  openDialog(
    consentType: 'marketing' | 'marketingThirdParties' | 'profiling' | 'profilingThirdParties'
  ) {
    this.store.dispatch(
      openDialog({
        content: undefined,
        urlToDownload: undefined,
        urlWithContext: undefined,
        componentType: ConsentInfoDialogComponent,
        panelClass: 'dn-dialog-medium',
        data: { consentType: consentType }
      })
    );
  }
}
